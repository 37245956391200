import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { loadFonts } from './plugins/webfontloader';

// PrimeVUE
import PrimeVue from 'primevue/config';
import esLang from '@/helpers/lang/primevue/es.js';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import DialogService from 'primevue/dialogservice';
import Tooltip from 'primevue/tooltip';
import { createPinia } from 'pinia';
import 'primevue/resources/primevue.min.css';                 // Core css
import 'primeicons/primeicons.css';                           // Icons
import 'primeflex/primeflex.css';                             // Primeflex
import '@/themes/default.css';                                // Custom theme

// Quill
import "quill/dist/quill.core.css";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
library.add(fad); library.add(far); library.add(fas);

// Component bus
import mitt from 'mitt';

// Primevue services
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

loadFonts();

// Create app
const app = createApp(App);

// Sentry config
import * as Sentry from "@sentry/vue";

// LOAD VITE_API_URL from .env
const VITE_API_URL = import.meta.env.VITE_API_URL;

Sentry.init({
    app,
    dsn: "https://d858c7108aca9289365ee1e12df1c3da@o4508925452484608.ingest.de.sentry.io/4508925454450768",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", VITE_API_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Inject emitter
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

// Mount app
app.use(createPinia())
.component('fa', FontAwesomeIcon)
.directive('ripple', Ripple)
.directive('styleclass', StyleClass)
.directive('tooltip', Tooltip)
.use(router)
.use(PrimeVue, 
    { 
        ripple: true,
        locale: esLang
    }
)
.use(DialogService)
.use(ConfirmationService)
.use(ToastService)
.mount('#app');


