import axios from 'axios';
import qs from 'qs';
import config from '@/../appconfig.js';

// Stores -- Caution, we have to to store instanciating below
import { useSessionStore } from '@/store/data/login/userSession';

/**
 * Stores instantiation. Pinia is not present in the execution of this file,
 * if we do = useWhateverStore() here it will fail in vue-router on initialization.
 * We have to initialize the store instance here and inject it using a method 
 * in axios interceptors or handles. We instantiate only one object to prevent
 * instantiation - deinstantiation useless cycle on every axios request. 
 */
let userSessionStore = null;
function injectSessionStore() {
    if(userSessionStore == null) { userSessionStore = useSessionStore(); }
}

export const RequestResult = {
    SUCCESS: 200,
    BAD_REQUEST: 400, 
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    UNSUPPORTED_MEDIA_TYPE: 415,
    INVALID_DATA: 422,
    FAILED_DEPENDENCY: 424,
    TOO_MANY_REQUESTS: 429,
    ERROR: 500
};

/**
 * Handle common success API response
 * @param {*} response 
 * @param {String | undefined} message
 * @return {{result: Number, message: String, data: Object}}
 */
export const handleSuccessResponse = (response, message = undefined) => {
    return {
        result: response.status,
        message: message,
        data: response.data
    };
};

/**
 * Handle common API error response
 * @param {*} error 
 * @param {String | undefined} message
 * @return {{result: Number, message: String, data: Object}}
 */
export const handleErrorResponse = (error, message = undefined, logouton401 = true) => {
    if (error && error.response && error.response.status === 401) {
        
        // Clear tokens on 401
        if(logouton401) {
            injectSessionStore();
            userSessionStore.logout();
        }

        return {
            result: RequestResult.UNAUTHORIZED,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 400) {
        return {
            result: RequestResult.BAD_REQUEST,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 403) {
        return {
            result: RequestResult.FORBIDDEN,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 404) {
        return {
            result: RequestResult.NOT_FOUND,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 409) {
        return {
            result: RequestResult.CONFLICT,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 415) {
        return {
            result: RequestResult.UNSUPPORTED_MEDIA_TYPE,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 422) {
        return {
            result: RequestResult.INVALID_DATA,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 424) {
        return {
            result: RequestResult.FAILED_DEPENDENCY,
            message: message,
            data: error.response.data
        };
    } else if (error && error.response && error.response.status === 429) {
        return {
            result: RequestResult.TOO_MANY_REQUESTS,
            message: 'Demasiadas peticiones. ' + message,
            data: error.response.data
        };
    } else {
        return {
            result: RequestResult.ERROR,
            message: message || 'Algo fué mal',
            data: null
        };
    }
};

// Axios instance
const axiosInstance = axios.create({
    baseURL: config.BaseEndpoint,
    timeout: 10000,
    dontAddBearer: false,
    paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
});

// Add a request interceptor to accept app/json
axiosInstance.interceptors.request.use(function (config) {
    config.headers.Accept = 'application/json';
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a request interceptor 
axiosInstance.interceptors.request.use(async function (config) {
    injectSessionStore(); 
    if(userSessionStore.isAuthenticated() && config.dontAddBearer !== true) {
        let bearer = await userSessionStore.getBearer();
        if(bearer != '') {
            config.headers.Authorization = 'Bearer ' + bearer;
        }
    }

    return config;
}, function(error) {
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(async function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default axiosInstance;