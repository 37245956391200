import { computed, reactive, ref, watch, onMounted } from 'vue';
import { defineStore } from 'pinia';
import localForage from "localforage";

// Bus component
import useEmitter from '@/helpers/composables/useEmitter';

/**
 * useConsentStore - A Pinia store for managing user consent for various categories of cookies.
 *
 * @typedef {Object} Service
 * @property {string} description - Description of the service.
 * @property {string|null} html - HTML content to be injected if consent is given.
 * @property {string|null} javascript - JavaScript code to be executed if consent is given.
 * @property {string[]} scripts - Array of external script URLs to be loaded if consent is given.
 * @property {boolean} accepted - Indicates whether the service is accepted by the user.
 *
 * @typedef {Object} Category
 * @property {string} name - Name of the category.
 * @property {string} description - Description of the category.
 * @property {boolean} mandatory - Indicates whether the category is mandatory.

 * @returns {Object} - The consent store.
 * @property {Config} config - Reactive configuration object.
 * @property {Object.<string, Service>} consentData - Reactive object mapping service names to their consent states.
 * @property {Ref<boolean>} consentChanged - Reactive variable to track changes in consent.
 * @property {Ref<boolean>} consentReady - Reactive variable to track if consent is ready.
 * @property {Function} loadConsent - Function to load consent data from storage.
 * @property {Function} saveConsent - Function to save consent data to storage.
 * @property {Function} clearConsent - Function to clear consent data from storage.
 * @property {ComputedRef<boolean>} hasSavedConsent - Computed property to check if there is saved consent data.
 * @property {ComputedRef<Function>} consentStatus - Computed property to get or set the consent status of a service.
 * @property {ComputedRef<Function>} itemsByCategory - Computed property to get or set services by category.
 * @property {ComputedRef<Function>} itemsByName - Computed property to get or set services by name.
 * @property {Function} acceptAllCookies - Function to accept all cookies.
 * @property {Function} rejectAllCookies - Function to reject all cookies except mandatory ones.
 * @property {Function} loadAcceptedItems - Function to load and execute accepted items (HTML, JavaScript, external scripts).
 */
export const useConsentStore = defineStore('consentStore', () => {
    const VALID_CATEGORIES = reactive({
        Necessary: {
            name: "Necesarias",
            description: "Cookies requeridas para que el sitio funcione normalmente. Estas cookies son obligatorias y no se pueden desactivar.",
            mandatory: true,
            items: []
        },
        Functional: {
            name: "Funcionales",
            description: "Cookies que aumentan la funcionalidad del sitio, como guardado de configurationes, opciones...",
            mandatory: false,
            items: []
        },
        Performance: {
            name: "Rendimiento",
            description: "Cookies usadas para analizar rendimiento y optimizar el sitio.",
            mandatory: false,
            items: []
        },
        Personalization: {
            name: "Personalización",
            description: "Cookies para personalizar la experiencia del usuario.",
            mandatory: false,
            items: []
        },
        Analytics: {
            name: "Analíticas",
            description: "Cookies para analizar el tráfico y comportamiento de los usuarios.",
            mandatory: false,
            items: []
        },
        Marketing: {
            name: "Publicidad",
            description: "Cookies para mostrar anuncios que no usan tus datos.",
            mandatory: false,
            items: []
        },
        BehaviorAds: {
            name: "Publicidad personalizada",
            description: "Cookies para mostrar anuncios personalizados en función de tus datos.",
            mandatory: false,
            items: []
        },
        Social: {
            name: "Social",
            description: "Cookies para integrar redes sociales.",
            mandatory: false,
            items: []
        },
        Geolocation: {
            name: "Geolocalización",
            description: "Cookies para detectar la ubicación del usuario.",
            mandatory: false,
            items: []
        },
        Other: {
            name: "Otras",
            description: "Cookies que no encajan en otras categorías.",
            mandatory: false,
            items: []
        }
    });

    let config = reactive({});
    let reevaluateCookies = ref(false);
    let isConfigLoaded = ref(false);
    const consentData = reactive({}); // Unified structure for elements and their consent states
    const consentChanged = ref(false); // Reactive variable to track changes in consent
    const consentReady = ref(false); // Reactive variable to track if consent is ready

    async function loadConfig() {
        if (!isConfigLoaded.value) {
            try {
                const response = await fetch('/config/cookies.json');
                if (response.ok) {
                    const rawConfig = await response.json();
                    config = {};

                    for (const [category, services] of Object.entries(rawConfig)) {
                        if (!VALID_CATEGORIES[category]) {
                            console.warn(`Invalid category: ${category}`);
                            continue;
                        }

                        config[category] = services.filter(service => {
                            const isValid = service &&
                                typeof service === 'object' &&
                                typeof Object.keys(service)[0] === 'string' &&
                                service[Object.keys(service)[0]].description &&
                                (
                                    service[Object.keys(service)[0]].html ||
                                    service[Object.keys(service)[0]].javascript ||
                                    (Array.isArray(service[Object.keys(service)[0]].scripts) && service[Object.keys(service)[0]].scripts.length > 0)
                                );

                            if (!isValid) {
                                console.warn(`Invalid service format: ${JSON.stringify(service)}`);
                            } else {
                                const serviceName = Object.keys(service)[0];
                                const { description, html, javascript, javascriptondenied, scripts } = service[serviceName];
                                const reactiveItem = reactive({
                                    name: serviceName,
                                    description,
                                    html: html || null,
                                    javascript: javascript || null,
                                    javascriptondenied: javascriptondenied || null,
                                    scripts: scripts || [],
                                    accepted: false || category.mandatory == true
                                });

                                consentData[serviceName] = reactiveItem;

                                // Check if category has an item with same name. If not add it.
                                if (!VALID_CATEGORIES[category].items.some(item => item.name === serviceName)) {
                                    VALID_CATEGORIES[category].items.push(reactiveItem);
                                }
                            }

                            return isValid;
                        });
                    }

                    isConfigLoaded.value = true;
                } else {
                    console.error('Failed to load cookies.json');
                }
            } catch (error) {
                console.error('Error loading cookies.json:', error);
            }
        }

        loadConsent();
        consentReady.value = true;

        return config;
    }

    const hasSavedConsent = computed(async function() {
        try {
            try {
                let forage = await localForage.getItem('gdprConsent');
                if(forage != null) return true;
            } catch (error) {
                console.error('Error loading forage consent:', error);
            }

            try {
                var local = localStorage.getItem('gdprConsent');
                if(local != null) return true;
            } catch (error) {
                console.error('Error loading local consent:', error);
            }

            try {
                let testcookie = '';
                const cookie = document.cookie.split('; ').find(function(row) {
                    return row.startsWith('gdprConsent=');
                });

                if (cookie) {
                    return true;
                }
            } catch (error) {
                console.error('Error loading cookie consent:', error);
            }

            // Noone worked, there is not saved content
            return false;
        } catch (error) {
            return false;
        }
    });

    async function saveConsent() {
        try {
            const consent = JSON.stringify(
                Object.fromEntries(
                    Object.entries(consentData).map(function([key, value]) {
                        return [key, value.accepted];
                    })
                )
            );
            
            const numberOfConsentData = Object.keys(consentData).length;

            let isSaved = false;
            const localForageCorrect = true;

            try {
                await localForage.setItem('gdprConsent', consent);
                await localForage.setItem('gdprAllConsentCount', numberOfConsentData);

                isSaved = true;
            } catch (error) {
                console.error('Error saving consent:', error);
                localForageCorrect = false;
            }
            
            if (!localForageCorrect && typeof localStorage !== 'undefined') {
                localStorage.setItem('gdprConsent', consent);
                localStorage.setItem('gdprAllConsentCount', numberOfConsentData);
                isSaved = true;
            } else if(!localForageCorrect) {
                document.cookie = `gdprConsent=${consent};path=/;`; // Fallback to cookies
                document.cookie = `gdprAllConsentCount=${numberOfConsentData};path=/;`;

                isSaved = true;
            }

            if(isSaved) {
                loadAcceptedItems();
            }
        } catch (error) {
            console.error('Error saving consent:', error);
        }
    }

    async function loadConsent() {
        try {
            let dataLoaded = false;
            let allConsentLoaded = false;
            let storedConsent = {};
            let storedAllConsentCount = 0;

            const localForageCorrect = true;
            try {
                storedConsent = JSON.parse(await localForage.getItem('gdprConsent') || '{}');
                // if storedConsent is an empty object
                if (Object.keys(storedConsent).length !== 0) {
                    dataLoaded = true;
                }

                storedAllConsentCount = parseSafeNumber(await localForage.getItem('gdprAllConsentCount') || 0);
                if (storedAllConsentCount != 0) { allConsentLoaded = true; }
            } catch (error) {
                console.error('Error loading consent:', error);
                localForageCorrect = false
            }


            if (!localForageCorrect && typeof localStorage !== 'undefined') {
                storedConsent = JSON.parse(localStorage.getItem('gdprConsent') || '{}');
                dataLoaded = true;

                storedAllConsentCount = parseSafeNumber(localStorage.getItem('gdprAllConsentCount') || 0);
                if (storedAllConsentCount != 0) { allConsentLoaded = true; }
            } else if(!localForageCorrect) {
                const cookie = document.cookie.split('; ').find(function(row) {
                    return row.startsWith('gdprConsent=');
                });

                if (cookie) {
                    storedConsent = JSON.parse(cookie.split('=')[1]);
                    dataLoaded = true;
                }

                const allCookie = document.cookie.split('; ').find(function(row) {
                    return row.startsWith('gdprAllConsentCount=');
                });

                if (allCookie) {
                    storedAllConsentCount = parseSafeNumber(allCookie.split('=')[1]);
                    if (storedAllConsentCount != 0) { allConsentLoaded = true; }
                }
            }

            if(dataLoaded) {
                // Copy data
                for (const [key, value] of Object.entries(storedConsent)) {
                    if (key in consentData) {
                        consentData[key].accepted = value;
                    }
                }

                // Count data entries
                if (allConsentLoaded || storedAllConsentCount == 0) {
                    var actualEntriesNumber = Object.entries(consentData).length;
                    if (actualEntriesNumber !== storedAllConsentCount) {
                        // There are new cookies that user has not accepted yet, show the banner
                        reevaluateCookies.value = true;
                    }
                }

                // And deploy cookies
                loadAcceptedItems();
            } else {
                // Preload accepted cookies so custom interface starts with the accepter
                // but not saved (!IMPORTANT FOR GDPR NOT TO SAVE PREACEPTED COOKIES)
                acceptAllCookies(false);
            }
        } catch (error) {
            console.error('Error loading consent:', error);
        }
    }

    function clearConsent() {
        try {
            if (typeof localStorage !== 'undefined') {
                localStorage.removeItem('gdprConsent');
            } else {
                document.cookie = 'gdprConsent=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
            Object.keys(consentData).forEach(function(key) {
                consentData[key].accepted = false;
            });
            consentChanged.value = false;
        } catch (error) {
            console.error('Error clearing consent:', error);
        }
    }

    const consentStatus = computed({
        get() {
            return function(name) {
                return consentData[name]?.accepted || false;
            };
        },
        set(name, value) {
            if (consentData[name]) {
                consentData[name].accepted = value;
                saveConsent();
            } else {
                console.error(`Service not found: ${name}`);
            }
        }
    });

    const allCategoriesWithItems = computed({
        get() {
            return Object.entries(VALID_CATEGORIES).map(([categoryKey, categoryInfo]) => {
                const items = config[categoryKey]?.map(service => {
                    const serviceName = Object.keys(service)[0];
                    if (serviceName && consentData[serviceName]) {
                        return { name: serviceName, ...consentData[serviceName] };
                    }
                    return null;
                }).filter(Boolean) || [];
    
                return {
                    ...categoryInfo,
                    items
                };
            }).filter(category => category.items.length > 0);
        },
        set(updatedCategories) {
            updatedCategories.forEach(category => {
                category.items.forEach(item => {
                    if (consentData[item.name]) {
                        consentData[item.name].accepted = item.accepted;
                    }
                });
            });
        }
    });

    const itemsByCategory = computed({
        get() {
            return function(category) {
                if (!config || typeof config !== 'object' || Object.keys(config).length === 0) {
                    console.error('Config is not properly loaded or is empty.');
                    return [];
                }
                if (!VALID_CATEGORIES[category]) {
                    console.warn(`Category '${category}' is not valid.`);
                    return [];
                }
                return config[category]?.map(function(service) {
                    if (!service || typeof service !== 'object' || Object.keys(service).length === 0) {
                        console.warn(`Invalid service structure: ${JSON.stringify(service)}`);
                        return null;
                    }
                    const serviceName = Object.keys(service)[0];
                    if (!serviceName || !(serviceName in consentData)) {
                        console.warn(`Service name '${serviceName}' not found in consent data.`);
                        return null;
                    }
                    return { ...service[serviceName], accepted: consentData[serviceName]?.accepted || false };
                }).filter(Boolean) || [];
            };
        },
        set(category, elements) {
            if (!VALID_CATEGORIES[category]) {
                console.error(`Invalid category: ${category}`);
                return;
            }
            elements.forEach(function(element) {
                const serviceName = element.name;
                if (consentData[serviceName]) {
                    consentData[serviceName].accepted = element.accepted;
                }
            });
            saveConsent();
        }
    });

    const itemsByName = computed({
        get() {
            return function(name) {
                return consentData[name] || null;
            };
        },
        set(name, element) {
            if (consentData[name]) {
                consentData[name].accepted = element.accepted;
                saveConsent();
            } else {
                console.error(`Service not found: ${name}`);
            }
        }
    });

    function acceptAllCookies(save = true) {
        Object.keys(consentData).forEach(function(key) {
            consentData[key].accepted = true;
        });

        // Autosave
        if(save) {
            saveConsent(true);
        }
    }

    function rejectAllCookies() {
        // We should not reject mandatory cookies
        Object.keys(consentData).forEach(function(key) {
            const category = Object.keys(config).find(category => config[category].some(service => Object.keys(service)[0] === key));
            if (category && !VALID_CATEGORIES[category].mandatory) {
                consentData[key].accepted = false;
            }
        });

        // Autosave
        saveConsent();
    }

    // Watch consentData for changes and update consentChanged
    watch(() => {
            return Object.values(consentData).map(function(item) {
                return item.accepted;
            });
        },
        () => {
            consentChanged.value = true;
        }
    );

    function loadAcceptedItems() {
            // Remove previously added elements/scripts if they are no longer accepted
            document.querySelectorAll('[data-consent-item]').forEach(function(element) {
                const name = element.getAttribute('data-consent-item');
                if (!consentData[name]?.accepted) {
                    element.remove();
                }
            });

        Object.entries(consentData).forEach(function([name, item]) {
            if (item.accepted) {
                // Insert HTML if available
                if (item.html && !document.querySelector(`[data-consent-item="${name}"]`)) {
                    const container = document.createElement('div');
                    container.setAttribute('data-consent-item', name);
                
                    // Insert the HTML excluding script tags
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = item.html;
                
                    Array.from(tempDiv.childNodes).forEach(node => {
                        if (node.tagName === 'SCRIPT') {
                            const script = document.createElement('script');
                            if (node.src) {
                                script.src = node.src;
                                script.async = node.async || false;
                            } else {
                                script.textContent = node.textContent;
                            }
                            // Append the script to the container instead of the document body
                            container.appendChild(script);
                
                            // Remove the script from tempDiv
                            node.remove();
                        } else {
                            container.appendChild(node.cloneNode(true));
                        }
                    });
                
                    // Append the container to the document body
                    document.body.appendChild(container);
                }

                // Execute JavaScript if available
                if (item.javascript) {
                    try {
                        Promise.resolve().then(() => {
                            eval(item.javascript);
                        });
                    } catch (error) {
                        console.error('Error executing JavaScript for service:', error);
                    }
                }

                // Load external scripts
                item.scripts.forEach(scriptUrl => {
                    if (!document.querySelector(`[data-consent-item="${name}"][src="${scriptUrl}"]`)) {
                        const script = document.createElement('script');
                        script.src = scriptUrl;
                        script.async = true;
                        script.setAttribute('data-consent-item', name);
                        document.body.appendChild(script);
                    }
                });
            }

            if(!item.accepted) {
                // Execute Rejected JavaScript if available
                if (item.javascriptondenied) {
                    try {
                        Promise.resolve().then(() => {
                            eval(item.javascriptondenied);
                        });
                    } catch (error) {
                        console.error('Error executing denied JavaScript for service:', error);
                    }
                }
            }
        });
    }

    // Events
    onMounted(() => {
        loadConfig();
    });

    // Helpers
    function parseSafeNumber(str) {
        const num = Number(str);
        if (Number.isNaN(num)) return 0; 
        return num;
    }

    return {
        config,
        consentData, consentChanged, consentReady,
        loadConsent, saveConsent, clearConsent,
        hasSavedConsent, consentStatus,
        itemsByCategory, itemsByName, allCategoriesWithItems,
        acceptAllCookies, rejectAllCookies,
        loadAcceptedItems, reevaluateCookies
    };
});