<script setup>
import { ref, reactive, watch } from 'vue';

// Components
import Divider from 'primevue/divider';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';

// Stores
import { useSessionStore } from '@/store/data/login/userSession';
import { useUserPermissionsStore } from '@/store/data/user/userPermissions';
import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

// Helpers
import { getRepresentativeLetters, toPastel } from '@/helpers/strings';
import { imageToSrc } from '@/helpers/api/imageAPIConverter';

// Stores
const userSessionStore = useSessionStore();
const userPermissionsStore = useUserPermissionsStore();

// Dummy menu so the Menu Component shows the html in the 'Item' template
const menuItems = [{ label: 'Dummy' }];

// Login components
const showLoginDialog = () => {
    emitter.emit('show-login-dialog');
};

const logout = () => {
    userSessionStore.logout();
};

// Process permissions
const headerMenuPermissionsDefault = () => ({
    adminMenuShow: userPermissionsStore.hasClaim('user:editothers') 
        || userPermissionsStore.hasClaim('enterprise:manager') ||
        userPermissionsStore.hasClaim('location:manage')
});

const headerMenuPermissions = reactive(headerMenuPermissionsDefault());

watch(() => userPermissionsStore.userPermissions, () => {
    Object.assign(headerMenuPermissions, headerMenuPermissionsDefault());
}, { deep: true });

// Menu open and close
const user_menu = ref(null);
const openMenu = ($ev) => { user_menu.value.show($ev); };
const closeMenu = ($ev) => { user_menu.value.hide($ev); };
const toggleMenu = ($ev) => { user_menu.value.toggle($ev); };

// Define expose for open and close the menu
defineExpose({ openMenu, closeMenu, toggleMenu });

</script>

<template>
    <!-- User menu -->
    <Menu ref="user_menu" id="overlay_user_menu" :popup="true" :model="menuItems"
        class="shadow-6 bg-white" e >
        <template #start>
            <button class="w-full p-link flex align-items-center p-2 pl-3 text-color border-noround">
                <Avatar :label="imageToSrc(userSessionStore.sessionState.user.avatar).src ? 
                            null : getRepresentativeLetters(userSessionStore.sessionState.user.username, 2)"
                        :image="imageToSrc(userSessionStore.sessionState.user.avatar).src"
                        :style="{'background-color': toPastel((userSessionStore.sessionState.user.username))}"
                        class="mr-2 smallmenuavatar" shape="circle"
                        size="normal" />
                <div class="flex flex-column align">
                    <span class="font-bold">{{ userSessionStore.sessionState.user.username }}</span>
                    <span class="text-sm">Holachollero</span>
                </div>
            </button>
            <Divider type="solid" class="my-2" />
        </template>
        <template #item>
            <router-link :to="router.resolve({ name: 'UserProfileDeals' }).href">
                <button class="w-full p-link flex align-items-center p-2 pl-4 text-black hover:bg-yellow-500 border-noround">
                    <i class="pi pi-check-circle" />
                    <span class="ml-2">Mis chollos</span>
                </button>
            </router-link>
            <router-link v-if="headerMenuPermissions.adminMenuShow" :to="router.resolve({ name: 'Admin' }).href">
                <button class="w-full p-link flex align-items-center p-2 pl-4 text-black hover:bg-yellow-500 border-noround">
                    <i class="pi pi-cog" />
                    <span class="ml-2">Administración</span>
                </button>
            </router-link>
            <router-link v-if="headerMenuPermissions.adminMenuShow" :to="router.resolve({ name: 'DealEditor' }).href">
                <button class="w-full p-link flex align-items-center p-2 pl-4 text-black hover:bg-yellow-500 border-noround">
                    <i class="pi pi-cog" />
                    <span class="ml-2">Crear nuevo chollo</span>
                </button>
            </router-link>
        </template>
        <template #end>
            <Divider type="solid" class="my-2" />
            <button class="w-full p-link flex align-items-center p-2 pl-4 text-black hover:bg-yellow-500 border-noround"
                    @click="logout()">
                <i class="pi pi-sign-out" />
                <span class="ml-2">Cerrar sesión</span>
            </button>
        </template>
    </Menu>
</template>

<style scoped>

@media only screen and (min-width:1730px) { 
    #overlay_user_menu {
        width: 14.5rem;
        margin-left: -180px;
        margin-top: 7px;
    }
}

@media only screen and (min-width:1344px) and (max-width: 1729px) { 
    #overlay_user_menu {
        width: 14.5rem;
        margin-top: 7px;
    }
}

@media only screen and (min-width:992px) and (max-width: 1344px) { 
    #overlay_user_menu {
        width: 14.5rem;
        margin-top: 7px;
    }
}
</style>